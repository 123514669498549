<template>
    <b-row class="pr-1 pl-1">
        <b-col cols="12" xl="6" lg="12" md="12">
            <table class="mt-2 mt-xl-0 w-100">
                <tr>
                    <th class="pb-50" style="width: 250px">
                        <span class="font-weight-bold"> Dades del prenedor </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                        {{ policy_data.taker_data }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                        <span class="font-weight-bold"> Direcció de risc </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                        {{ policy_data.risk_address }}
                    </td>
                </tr>
            </table>
        </b-col>
        <b-col cols="12" xl="6" lg="12" md="12">
            <table class="mt-2 mt-xl-0 w-100">
                <tr>
                    <th class="pb-50" style="width: 250px">
                        <span class="font-weight-bold"> Activitat </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                        {{ policy_data.activity }}
                    </td>
                </tr>
            </table>
        </b-col>

        <b-col sm="12" class="pb-2 pt-1">
            <h4>Capitals</h4>
        </b-col>

        <b-col v-for="(policy_data_capital) in policy_data_capitals"
               :key="policy_data_capital.id" cols="12" class="pb-2">
            <b-row>
                <b-col cols="12" xl="6" lg="12" md="12">
                    <table class="mt-2 mt-xl-0 w-100">
                        <tr>
                            <th class="pb-50" style="width: 250px">
                                <span class="font-weight-bold"> Capital </span>
                            </th>
                            <td class="pb-50 text-capitalize">
                                {{ policy_data_capital.type }}
                            </td>
                        </tr>
                    </table>
                </b-col>
                <b-col cols="12" xl="6" lg="12" md="12">
                    <table class="mt-2 mt-xl-0 w-100">
                        <tr>
                            <th class="pb-50" style="width: 250px">
                                <span class="font-weight-bold"> Descripció </span>
                            </th>
                            <td class="pb-50 text-capitalize">
                                {{ policy_data_capital.description }}
                            </td>
                        </tr>
                    </table>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import {BFormGroup, BFormInput, BRow, BCol, BOverlay, BFormRadio, BButton} from 'bootstrap-vue'
import {createHelpers} from "vuex-map-fields"
import vSelect from "vue-select"
import {mapState} from "vuex"

const {mapFields} = createHelpers({
    getterType: 'insurancePolicies/getField',
    mutationType: 'insurancePolicies/updateField',
})

export default {
    name: "ViewPymes",
    components: {
        BFormGroup,
        BRow,
        BCol,
        BFormInput,
        BButton,
    },
    data() {
        return {
            showLoading: false,
        }
    },
    computed: {
        ...mapFields({
            policy_data: 'policy_data',
            policy_data_capitals: 'policy_data_capitals'
        }),
    },
}
</script>