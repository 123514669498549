<template>
    <b-row sm="12" class="pr-1 pl-1">
        <b-col cols="12" xl="6" lg="12" md="12">
            <table class="mt-2 mt-xl-0 w-100">
                <tr>
                    <th class="pb-50" style="width: 250px">
                        <span class="font-weight-bold"> DNI </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                        {{ policy_data.dni }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                        <span class="font-weight-bold"> Capital de continent </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                        {{ policy_data.mainland_capital }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                        <span class="font-weight-bold"> Capital de contingut </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                        {{ policy_data.content_capital }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                        <span class="font-weight-bold"> Valor contingut </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                        {{ policy_data.content_value }}
                    </td>
                </tr>
            </table>
        </b-col>
        <b-col cols="12" xl="6" lg="12" md="12">
            <table class="mt-2 mt-xl-0 w-100">
                <tr>
                    <th class="pb-50" style="width: 250px">
                        <span class="font-weight-bold"> Domicili de risc </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                        {{ policy_data.risk_address }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                        <span class="font-weight-bold"> Referència cadastral </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                        {{ policy_data.cadastral_reference }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                        <span class="font-weight-bold"> Mesures de seguretat </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                        {{ policy_data.security_measures }}
                    </td>
                </tr>
            </table>
        </b-col>
    </b-row>
</template>

<script>
import {BFormGroup, BFormInput, BRow, BCol, BOverlay} from 'bootstrap-vue'
import {createHelpers} from "vuex-map-fields"
import vSelect from "vue-select"
import {mapState} from "vuex"

const {mapFields} = createHelpers({
    getterType: 'insurancePolicies/getField',
    mutationType: 'insurancePolicies/updateField',
})

export default {
    name: "ViewHouse",
    components: {
        BFormGroup,
        BRow,
        BCol,
        BFormInput,
    },
    data() {
        return {
            showLoading: false,
            activities: [],
        }
    },
    computed: {
        ...mapState('insurancePolicies', ['validation_policy_data']),
        ...mapFields({
            policy_data: 'policy_data'
        }),
    },
}
</script>