<template>
    <b-row class="pr-1 pl-1">
        <b-col cols="12" xl="6" lg="12" md="12">
            <table class="mt-2 mt-xl-0 w-100">
                <tr>
                    <th class="pb-50" style="width: 250px">
                        <span class="font-weight-bold"> Dades de l'empresa </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                        {{ policy_data.company_data }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                        <span class="font-weight-bold"> Activitat </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                        {{ policy_data.activity }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                        <span class="font-weight-bold"> Volum de facturació </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                        {{ policy_data.billing_volume }}
                    </td>
                </tr>
            </table>
        </b-col>
        <b-col cols="12" xl="6" lg="12" md="12">
            <table class="mt-2 mt-xl-0 w-100">
                <tr>
                    <th class="pb-50" style="width: 250px">
                        <span class="font-weight-bold"> Nombre de treballadors </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                        {{ policy_data.workers_number }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                        <span class="font-weight-bold"> Capitals </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                        {{ policy_data.capitals }}
                    </td>
                </tr>
            </table>
        </b-col>
    </b-row>
</template>

<script>
import {BFormGroup, BFormInput, BRow, BCol, BOverlay} from 'bootstrap-vue'
import {createHelpers} from "vuex-map-fields"
import vSelect from "vue-select"
import {mapState} from "vuex"

const {mapFields} = createHelpers({
    getterType: 'insurancePolicies/getField',
    mutationType: 'insurancePolicies/updateField',
})

export default {
    name: "ViewRcProfessional",
    components: {
        BFormGroup,
        BOverlay,
        BRow,
        BCol,
        BFormInput,

        vSelect,
    },
    data() {
        return {
            showLoading: false,
            activities: [],
        }
    },
    computed: {
        ...mapState('insurancePolicies', ['validation_policy_data']),
        ...mapFields({
            policy_data: 'policy_data'
        }),
    },
}
</script>