<template>
    <b-overlay
        variant="white"
        :show="showLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
    >
        <b-row
            v-for="(policy_document) in policy_documents_data.policy_documents"
            :key="policy_document.id"
            class="pb-1 pt-1 pl-1 pr-1">
            <b-col lg="3" md="12" sm="12">
                <table class="mt-3 mt-xl-0 w-100">
                    <tr>
                        <th class="pb-50" style="width: 250px">
                            <span class="font-weight-bold d-block margin-button-top" > Nom Arxiu </span>
                        </th>
                        <td class="pb-50 text-capitalize d-block margin-button-top">
                            {{ policy_document.document_name }}
                        </td>
                    </tr>
                </table>
            </b-col>
            <b-col v-if="policy_document.document" lg="1" md="12" sm="12">
                <a :href="baseUrl+policy_document.document" target="_blank" >
                    <b-button
                        variant="outline-success"
                        class="mt-0 "
                    >
                        <span>Veure</span>
                    </b-button>
                </a>
            </b-col>
        </b-row>
    </b-overlay>
</template>

<script>
import { BRow, BCol, BOverlay, BButton } from 'bootstrap-vue'
import {createHelpers} from "vuex-map-fields"
import {baseUrl} from "@/constants/app"

const {mapFields} = createHelpers({
    getterType: 'insurancePolicies/getField',
    mutationType: 'insurancePolicies/updateField',
})

export default {
    name: "ViewDocuments",
    components: {
        BOverlay,
        BRow,
        BCol,
        BButton,
    },
    data() {
        return {
            baseUrl,
            showLoading: false,
        }
    },
    computed: {
        ...mapFields({
            policy: 'policy',
            policy_documents_data: 'policy_documents_data',
        }),
    },
}
</script>

<style lang="scss">
    .margin-button-top{
        margin-top: 5px
    }
</style>