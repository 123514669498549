<template>
    <b-row class="pr-1 pl-1">
        <b-col cols="12" xl="6" lg="12" md="12">
            <table class="mt-2 mt-xl-0 w-100">
                <tr>
                    <th class="pb-50" style="width: 250px">
                        <span class="font-weight-bold"> Capital </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                        {{ policy_data.capitals }}
                    </td>
                </tr>
            </table>
        </b-col>
    </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import {createHelpers} from "vuex-map-fields"

const {mapFields} = createHelpers({
    getterType: 'insurancePolicies/getField',
    mutationType: 'insurancePolicies/updateField',
})

export default {
    name: "viewLife",
    components: {
        BRow,
        BCol,
    },
    data() {
        return {
            showLoading: false,
        }
    },
    computed: {
        ...mapFields({
            policy_data: 'policy_data'
        }),
    },
}
</script>