<template>
    <b-card no-body>
        <b-card-body>
            <b-row>
                <b-col sm="12" class="pb-2">
                    <h4>
                        Informació de la pòlissa
                    </h4>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" xl="4" lg="12" md="12">
                    <table class="mt-2 mt-xl-0 w-100">
                        <tr v-if="policy.company">
                            <th class="pb-50">
                                <span class="font-weight-bold"> Cia </span>
                            </th>
                            <td class="pb-50 text-capitalize">
                                {{ policy.company }}
                            </td>
                        </tr>
                        <tr v-if="policy.policy_number">
                            <th class="pb-50">
                                <span class="font-weight-bold"> Numero pòlissa </span>
                            </th>
                            <td class="pb-50 text-capitalize">
                                {{ policy.policy_number }}
                            </td>
                        </tr>
                        <tr v-if="policy.risk">
                            <th class="pb-50">
                                <span class="font-weight-bold"> Risc </span>
                            </th>
                            <td class="pb-50 text-capitalize">
                                {{ policy.risk }}
                            </td>
                        </tr>
                        <tr v-if="policy.period">
                            <th class="pb-50">
                                <span class="font-weight-bold"> Període </span>
                            </th>
                            <td class="pb-50 text-capitalize">
                                {{ policiesPeriods.find(option => option.value === policy.period).name }}
                            </td>
                        </tr>
                    </table>
                </b-col>
                <b-col cols="12" xl="4" lg="12" md="12">
                    <table class="mt-2 mt-xl-0 w-100">
                        <tr v-if="policy.date_first_effect">
                            <th class="pb-50">
                                <span class="font-weight-bold"> Primer efecte </span>
                            </th>
                            <td class="pb-50 text-capitalize">
                                {{ policy.date_first_effect }}
                            </td>
                        </tr>
                        <tr v-if="policy.date_effect">
                            <th class="pb-50">
                                <span class="font-weight-bold"> Data efecte </span>
                            </th>
                            <td class="pb-50 text-capitalize">
                                {{ policy.date_effect }}
                            </td>
                        </tr>
                        <tr v-if="policy.date_expiration">
                            <th class="pb-50">
                                <span class="font-weight-bold"> Data venciment </span>
                            </th>
                            <td class="pb-50 text-capitalize">
                                {{ policy.date_expiration }}
                            </td>
                        </tr>
                        <tr v-if="policy.date_payment">
                            <th class="pb-50">
                                <span class="font-weight-bold"> Data pagament </span>
                            </th>
                            <td class="pb-50 text-capitalize">
                                {{ policy.date_payment }}
                            </td>
                        </tr>
                    </table>
                </b-col>
                <b-col cols="12" xl="4" lg="12" md="12">
                    <table class="mt-2 mt-xl-0 w-100">
                        <tr v-if="policy.value">
                            <th class="pb-50">
                                <span class="font-weight-bold"> Valor </span>
                            </th>
                            <td class="pb-50 text-capitalize">
                                {{ policy.value }}
                            </td>
                        </tr>
                        <tr v-if="policy.status">
                            <th class="pb-50">
                                <span class="font-weight-bold"> Estat </span>
                            </th>
                            <td :class="'background-text-' + policy.status" class="pb-50 text-capitalize" >
                                {{ policiesStatus.find(option => option.value === policy.status).name }}
                            </td>
                        </tr>
                    </table>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
import { BRow, BCol, BCard, BCardBody } from 'bootstrap-vue'
import { createHelpers } from "vuex-map-fields"
import { policiesPeriods, policiesStatus } from "@/constants/insurancePolicies"

const {mapFields} = createHelpers({
    getterType: 'insurancePolicies/getField',
    mutationType: 'insurancePolicies/updateField',
})

export default {
    name: "ViewPolicy",
    components: {
        BRow,
        BCol,
        BCard,
        BCardBody,
    },
    data() {
        return {
            policiesStatus,
            policiesPeriods,
        }
    },
    computed: {
        ...mapFields({
            policy: 'policy'
        }),
    },
}
</script>

<style lang="scss">

.background-text-in_progress {
    color: #ffc107;
}

.background-text-accepted {
    color: #28c76f;
}

.background-text-sent_to_client {
    color: #00cfe8;
}

.background-text-anulled {
    color: #82868b;
}

.background-text-denied {
    color: #ea5455;
}
</style>