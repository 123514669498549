<template>
    <div>

        <b-table
            :items="policy.policy_history"
            responsive
            :fields="fields"
            class="mb-0"
        >
        </b-table>
    </div>
</template>

<script>
import { BTable } from 'bootstrap-vue'

import {createHelpers} from "vuex-map-fields"

const {mapFields} = createHelpers({
    getterType: 'insurancePolicies/getField',
    mutationType: 'insurancePolicies/updateField',
})

export default {
    name: "FormHistoryPolicies",
    components: {
        BTable,
    },
    data() {
        return {
            fields: [
                { key: 'date', label: 'Fecha' },
                { key: 'value', label: 'Prima' },
            ],
        }
    },
    computed: {
        ...mapFields({
            policy: 'policy',
        }),
    },
}
</script>

<style scoped>

</style>