<template>
    <div>
        <b-card class="p-1 p-lg-0">
            <b-tabs align="left">
                <b-tab active title="Informació general" lazy>
                    <b-overlay
                        variant="white"
                        :show="showLoading"
                        spinner-variant="primary"
                        blur="0"
                        opacity=".75"
                        rounded="sm"
                    >
                        <b-row class="pr-1 pl-1 pt-1">
                            <b-col cols="12" xl="6" lg="12" md="12">
                                <table class="mt-2 mt-xl-0 w-100">
                                    <tr v-if="policy.client_id">
                                        <th class="pb-50" style="width: 250px">
                                            <span class="font-weight-bold"> Client </span>
                                        </th>
                                        <td v-if="clients" class="pb-50 text-capitalize">
                                            {{ clients.find(option => option.id === policy.client_id).name }}
                                            {{ clients.find(option => option.id === policy.client_id).first_surname }}
                                            {{ clients.find(option => option.id === policy.client_id).second_surname }}
                                        </td>
                                    </tr>
                                </table>
                            </b-col>
                            <b-col cols="12" xl="6" lg="12" md="12">
                                <table class="mt-2 mt-xl-0 w-100">
                                    <tr v-if="policy.insurance_type">
                                        <th class="pb-50" style="width: 250px">
                                            <span class="font-weight-bold"> Ram </span>
                                        </th>
                                        <td v-if="policy.insurance_type" class="pb-50 text-capitalize">
                                            {{
                                                insurancesTypes.find(option => option.value === policy.insurance_type).name
                                            }}
                                        </td>
                                    </tr>
                                </table>
                            </b-col>
                        </b-row>

                        <hr>
                        <view-house v-if="policy.insurance_type === 'house'" ref="viewHouse"/>

                        <view-accidents-contract v-if="policy.insurance_type === 'accidents_contract'"
                                                 ref="viewAccidentsContract"/>

                        <view-business v-if="policy.insurance_type === 'business'" ref="viewBusiness"/>

                        <view-car-motocycle v-if="policy.insurance_type === 'car_motocycle'" ref="viewCarMotocycle"/>

                        <view-pymes v-if="policy.insurance_type === 'pymes'" ref="viewPymes"/>

                        <view-rc-professional v-if="policy.insurance_type === 'rc_professional'"
                                              ref="viewRcProfessional"/>

                        <view-daily-absense v-if="policy.insurance_type === 'daily_absence'" ref="viewDailyAbsense"/>

                        <view-accidents-particular v-if="policy.insurance_type === 'accidents_particular'"
                                                   ref="viewAccidentsParticular"/>
                        <view-adm-directives v-if="policy.insurance_type === 'adm_directives'" ref="viewAdmDirectives"/>

                        <view-travel-assistence v-if="policy.insurance_type === 'travel_assistence'" ref="viewTravelAssistence"/>
                        <view-estalvi v-if="policy.insurance_type === 'estalvi'" ref="viewEstalvi"/>

                        <view-rent v-if="policy.insurance_type === 'rent'" ref="viewRent"/>

                        <view-multirisks v-if="policy.insurance_type === 'multirisk'" ref="viewMultirisk"/>

                        <view-rc-particular v-if="policy.insurance_type === 'rc_particular'" ref="viewRcParticular"/>

                        <view-health v-if="policy.insurance_type === 'health'" ref="viewHealth"/>

                        <view-triplesport v-if="policy.insurance_type === 'triplesport'" ref="viewTriplesport"/>

                        <view-life v-if="policy.insurance_type === 'life'" ref="viewLife"/>
                    </b-overlay>
                </b-tab>
                <b-tab v-if="policy.id" title="Documents" lazy>
                    <view-documents ref="formDocuments"/>
                </b-tab>
                <b-tab v-if="policy.id" title="Històric de Primes" lazy>
                    <form-histoy-policies ref="formHistoryPolicies"/>
                </b-tab>
            </b-tabs>
        </b-card>

        <view-policy v-if="policy.id" ref="viewPolicy"/>
    </div>
</template>

<script>
import {BCard, BCol, BTab, BTabs, BOverlay, BRow} from 'bootstrap-vue'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import {POLICIES_LIST} from "@/router/web/constants"
import {insurancesTypes} from "@/constants/insurancePolicies"

import ViewPolicy from "@/views/insurance/policies/view/components/viewPolicy"
import ViewHouse from "@/views/insurance/policies/view/components/viewHouse"
import ViewAccidentsContract from "@/views/insurance/policies/view/components/viewAccidentsContract"
import ViewBusiness from "@/views/insurance/policies/view/components/viewBussiness"
import ViewCarMotocycle from "@/views/insurance/policies/view/components/ViewCarMotocycle"
import ViewPymes from "@/views/insurance/policies/view/components/viewPymes"
import ViewRcProfessional from "@/views/insurance/policies/view/components/viewRcProfessional"
import ViewDailyAbsense from "@/views/insurance/policies/view/components/viewDailyAbsense"
import ViewAccidentsParticular from "@/views/insurance/policies/view/components/viewAccidentsParticular"
import ViewAdmDirectives from "@/views/insurance/policies/view/components/viewAdmDirectives"
import viewTravelAssistence from "@/views/insurance/policies/view/components/viewTravelAssistence"
import ViewEstalvi from "@/views/insurance/policies/view/components/viewEstalvi"
import ViewRent from "@/views/insurance/policies/view/components/viewRent"
import viewMultirisks from "@/views/insurance/policies/view/components/viewMultirisks"
import viewRcParticular from "@/views/insurance/policies/view/components/viewRcParticular"
import viewHealth from "@/views/insurance/policies/view/components/viewHealth"
import viewTriplesport from "@/views/insurance/policies/view/components/viewTriplesport"
import viewLife from "@/views/insurance/policies/view/components/viewLife"

import ViewDocuments from "@/views/insurance/policies/view/components/viewDocuments"
import FormHistoyPolicies from "@/views/insurance/policies/form/components/formHistoryPolicies"

import {createHelpers} from "vuex-map-fields"
import {mapState} from "vuex"

const {mapFields} = createHelpers({
    getterType: 'insurancePolicies/getField',
    mutationType: 'insurancePolicies/updateField',
})

export default {
    name: "SheetView",
    components: {
        BCol,
        BRow,
        BCard,
        BOverlay,
        BTab,
        BTabs,

        ViewPolicy,
        ViewHouse,
        ViewAccidentsContract,
        ViewBusiness,
        ViewCarMotocycle,
        ViewPymes,
        ViewRcProfessional,
        ViewDailyAbsense,
        ViewDocuments,
        ViewAccidentsParticular,
        ViewAdmDirectives,
        viewTravelAssistence,
        ViewEstalvi,
        ViewRent,
        viewMultirisks,
        viewRcParticular,
        viewHealth,
        viewTriplesport,
        viewLife,

        FormHistoyPolicies,
    },
    data() {
        return {
            users: [],
            clients: [],
            edit: false,
            showLoading: false,
            SpanishLocale,

            insurancesTypes
        }
    },
    computed: {
        ...mapState('insurancePolicies', ['validation_policy', 'validation_policy_data']),
        ...mapFields({
            policy: 'policy',
            policy_data: 'policy_data',
            policy_data_capitals: 'policy_data_capitals',
            policy_data_travel_persons: 'policy_data_travel_persons',
        }),
    },
    mounted() {
        this.$store.commit('insurancePolicies/RESET_STATE')
        this.getClients()
        this.getPolicy()
    },
    methods: {
        getPolicy() {
            this.showLoading = true

            this.$store.dispatch('insurancePolicies/getPolicy', {
                id: this.$route.params.id,
                relations: ['client', 'policyHistory', 'policyData', 'policyDataCapitals', 'policyDocuments', 'policyDataTravelPersons'],
            })
            .then(response => {
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
        getClients() {
            this.showLoading = true

            this.$store.dispatch('client/getAllClients', {
                id: this.$route.params.id,
                relations: ['responsible'],
            })
            .then(response => {
                this.clients = response
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
        cancel() {
            this.$router.push(POLICIES_LIST)
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>