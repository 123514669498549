<template>
    <b-row class="pr-1 pl-1">
        <b-col cols="12" xl="6" lg="12" md="12">
            <table class="mt-2 mt-xl-0 w-100">
                <tr v-if="policy_data.modality">
                    <th class="pb-50">
                        <span class="font-weight-bold"> Modalitat </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                        {{ healthModalities.find(option => option.value === policy_data.modality).name }}
                    </td>
                </tr>
            </table>
        </b-col>
    </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import {createHelpers} from "vuex-map-fields"
import {healthModalities} from "@/constants/insurancePolicies"

const {mapFields} = createHelpers({
    getterType: 'insurancePolicies/getField',
    mutationType: 'insurancePolicies/updateField',
})

export default {
    name: "viewEstalvi",
    components: {
        BRow,
        BCol,
    },
    data() {
        return {
            showLoading: false,
            healthModalities
        }
    },
    computed: {
        ...mapFields({
            policy_data: 'policy_data'
        }),
    },
}
</script>